import { message } from 'antd';
import { useSelector } from 'react-redux';
import { useState, useEffect, useRef, useCallback } from 'react';
import { Platform } from 'react-native';
import { get, isNil, isEmpty } from 'lodash';
import cardValidator from 'card-validator';
import { UnivaPayParams } from '@common/types/action';
import {
  getTextErrors,
  cardNumberFormatter,
  cardExpirationDateFormatter,
  amountFormatter,
  toNonAccentVietnamese,
  CARD,
  STATUS_UNIVAPAY,
  SAVE_CARD_MODE,
  validateEmail,
  checkDisabledButton,
  checkStatusCard,
} from './constant';
import { getMessage } from '../../utils/locale';
import {
  URI_IMAGE_CARD,
  checkTypeCardPayment,
} from './../../utils/cardPayment';

export type ReceivedProps = {
  attributes: Record<string, any>;
  isConnected: boolean;
  appId: string;
  univaPayPayment: (data: UnivaPayParams) => Promise<any>;
  getCardSaveByUser: ({
    appId,
    userId,
  }: {
    appId: string;
    userId: string;
  }) => Promise<any>;
  groupActionId: any;
  onPress: (id: string | undefined, { groupActionId }?: any) => void;
  locale: string;
  id: string;
  loggedUserInApp: Record<string, any>;
};

const initialState = {
  cardName: '',
  cardNumber: '',
  cardExpiration: '',
  cardCvc: '',
  text: { status: null, message: null },
};

type CHECK_CARD = {
  niceType: string;
  type: URI_IMAGE_CARD;
  patterns: number[] | null;
  gaps: number[] | null;
  lengths: number[];
  code: {
    name: string;
    size: number;
  };
  matchStrength?: number | null;
};

const useUnivaPay = (props: ReceivedProps) => {
  const {
    attributes,
    isConnected,
    appId,
    univaPayPayment,
    getCardSaveByUser,
    groupActionId,
    onPress,
    locale,
    id,
    loggedUserInApp,
  } = props;

  const { email, paymentOptions, submitButton, saveCardOptions } = attributes;
  const { emailBuyer } = email || {};
  const { paymentAmount, typeCurrency } = paymentOptions || {};
  const { saveCardMode } = saveCardOptions || {};
  const { text: submitButtonText = 'Pay Now' } = submitButton || {};

  const onlySaveCard = saveCardMode === SAVE_CARD_MODE.ONLY_SAVE_CARD;

  const textErrors = getTextErrors();

  // const [loading, setLoading] = useState(false);
  const [state, setState] = useState<{
    loading: boolean;
    isSaveCard: boolean;
    recurring: Record<string, any> | null;
  }>({
    loading: false,
    isSaveCard: true,
    recurring: null,
  });

  const { loading, isSaveCard, recurring } = state;

  const [card, setCard] = useState<{
    cardName: string;
    cardNumber: string;
    cardExpiration: string;
    cardCvc: string;
    text: {
      status: 'successful' | 'failed' | 'notValidOrRequired' | null;
      message: string | null;
    };
  }>(initialState);

  const cardNameRef = useRef<any>(null);
  const cardNumberRef = useRef<any>(null);
  const [cursor, setCursor] = useState<Record<string, any>>({
    cursorCardName: null,
  });

  const { cardName, cardNumber, cardExpiration, cardCvc, text } = card;

  const checkTypeCard: CHECK_CARD = checkTypeCardPayment(cardNumber);

  const uriImage = !cardNumber
    ? URI_IMAGE_CARD['default']
    : cardNumber
    ? URI_IMAGE_CARD[checkTypeCard.type]
    : URI_IMAGE_CARD['invalid'];

  const isPassCardName = new RegExp(/^[A-Za-z].*.*[A-Za-z]$/g).test(cardName);

  const isPassCardNumber = checkTypeCard.lengths.includes(
    cardNumber.replace(/\s/g, '').length
  );

  const isPassCardExpiration =
    cardValidator.expirationDate(cardExpiration).isValid;

  const isPassCardCvc = cardCvc.length === checkTypeCard.code.size;

  const isPassEmail: boolean = emailBuyer && validateEmail(emailBuyer);

  const isCanvas =
    window?.location?.href &&
    window.location.href.split('/').includes('canvas');

  const isPassCard =
    isPassCardName &&
    isPassCardNumber &&
    isPassCardExpiration &&
    isPassCardCvc &&
    checkTypeCard.type !== 'invalid';

  const disableBtn = checkDisabledButton({
    onlySaveCard,
    recurring,
    loading,
    isPassCard,
    isPassEmail,
    textError: !!text.message && text.status === 'notValidOrRequired',
  });

  const amountPayload = amountFormatter(paymentAmount, typeCurrency);

  const toggleSaveCard = () =>
    setState((prev) => ({ ...prev, isSaveCard: !prev.isSaveCard }));

  useEffect(() => {
    setCard(initialState);
  }, [id]);

  const recurringByUserLoggedIn = useCallback(async () => {
    if (!loggedUserInApp) return;
    try {
      const {
        recurring,
        recurring: { data_card },
      } = await getCardSaveByUser({
        appId,
        userId: loggedUserInApp.userId,
      });

      if (recurring) {
        if (!onlySaveCard) {
          setCard((prev) => ({
            ...prev,
            cardName: data_card.cardholder,
            cardNumber: `•••• •••• •••• ${data_card.last_four}`,
            cardExpiration: `${data_card.exp_month}/${data_card.exp_year
              .toString()
              .substring(2, 4)}`,
            cardCvc: '•••',
          }));
        }
        setState((prev) => ({
          ...prev,
          isSaveCard: false,
          recurring: recurring,
        }));
      }
    } catch (error: any) {
      // console.log('error', error.message);
    }
  }, []);

  useEffect(() => {
    recurringByUserLoggedIn();
  }, []);

  useEffect(() => {
    if (!isCanvas) {
      const { status: statusCard, message: messageCard } = checkStatusCard({
        onlySaveCard,
        recurring,
        isConnected,
        isPassCardName,
        isPassCardNumber,
        isPassCardExpiration,
        isPassCardCvc,
        isPassEmail,
        cardName,
        cardNumber,
        cardExpiration,
        cardCvc,
        amount: amountPayload,
        locale,
      });

      setCard((prev) => ({
        ...prev,
        text: {
          status: statusCard,
          message: messageCard,
        },
      }));
    }
  }, [
    isConnected,
    emailBuyer,
    amountPayload,
    cardName,
    cardNumber,
    cardExpiration,
    cardCvc,
  ]);

  const handleCheckRequired = () => {
    if (!text.status) {
      const keys: Record<string, string> = {
        cardName: cardName,
        cardNumber: cardNumber,
        cardExpiration: cardExpiration,
        cardCvc: cardCvc,
        emailBuyer: emailBuyer,
      };

      const cardRequired = Object.keys(keys).find((key) => !keys[key]);
      if (!cardRequired) {
        setCard((prev) => ({ ...prev, text: { status: null, message: null } }));
        return;
      }

      const checkStatusCardRequired = [
        CARD.CARD_NAME,
        CARD.CARD_NUMBER,
        CARD.CARD_EXPIRATION,
        CARD.CARD_CVC,
      ].includes(cardRequired)
        ? 'notValidOrRequired'
        : null;
      const checkMessageCardRequired =
        cardRequired === CARD.CARD_NAME
          ? textErrors.cardNameRequired
          : cardRequired === CARD.CARD_NUMBER
          ? textErrors.cardNumberRequired
          : cardRequired === CARD.CARD_EXPIRATION
          ? textErrors.cardExpirationRequired
          : cardRequired === CARD.CARD_CVC
          ? textErrors.cardCvcRequired
          : cardRequired === 'emailBuyer'
          ? textErrors.emailRequired
          : null;

      setCard((prev) => ({
        ...prev,
        text: {
          status: checkStatusCardRequired,
          message: checkMessageCardRequired,
        },
      }));
    } else {
      setCard((prev) => ({ ...prev, text: { status: null, message: null } }));
    }
  };

  const handleChange = (value: string, name: string) => {
    setCard((prev) => {
      switch (name) {
        case CARD.CARD_NAME:
          return {
            ...prev,
            [name]: toNonAccentVietnamese(value.toUpperCase()),
          };
        case CARD.CARD_NUMBER:
          return {
            ...prev,
            [name]: cardNumberFormatter(prev.cardNumber, value),
          };
        case CARD.CARD_EXPIRATION:
          return {
            ...prev,
            [name]: cardExpirationDateFormatter(prev.cardExpiration, value),
          };
        default:
          return { ...prev, [name]: value };
      }
    });
  };

  const handleSubmit = async () => {
    handleCheckRequired();
    if (text.status && text.status !== 'successful') {
      return;
    }
    try {
      // setLoading(true);
      setState((prev) => ({ ...prev, loading: true }));

      const saveCard = loggedUserInApp && (onlySaveCard || isSaveCard);

      const payload = {
        appId: appId,
        userId: loggedUserInApp?.userId,
        paymentType: 'card',
        type: saveCard ? 'recurring' : 'one_time',
        email: emailBuyer,
        dataCard: {
          cardholder: cardName,
          card_number: cardNumber.replace(/\s/g, ''),
          exp_month: cardExpiration.split('/')[0],
          exp_year: cardExpiration.split('/')[1],
          cvv: cardCvc,
          // cvv_authorize: {
          //   enabled: true,
          // },
        },
        period: 'daily',
        ...(!onlySaveCard && {
          amount: amountPayload,
          currency: typeCurrency,
        }),
        saveCardOptions: { saveCard, onlySaveCard },
      };

      const response = await univaPayPayment(payload);

      if (response.status === STATUS_UNIVAPAY.SUCCESSFUL) {
        recurringByUserLoggedIn();
        setCard((prev) => ({
          ...prev,
          text: {
            status: 'successful',
            message: onlySaveCard
              ? 'Save card successful'
              : 'Payment successful',
          },
        }));
        handleAction('successActions', attributes?.successActions);
      } else {
        const getError = get(response, 'error.message');
        setCard((prev) => ({
          ...prev,
          text: {
            status: 'failed',
            message: getMessage({ id: getError, locale }),
          },
        }));
        handleAction('failedActions', attributes?.failedActions);
      }
    } catch (error: any) {
      const getError = get(error.response, 'data.errors[0].reason') || 'Error';

      setCard((prev) => ({
        ...prev,
        text: {
          status: 'failed',
          message: getMessage({ id: getError, locale }),
        },
      }));

      handleAction('failedActions', attributes?.failedActions);
    } finally {
      // setLoading(false);
      setState((prev) => ({ ...prev, loading: false }));
    }
  };

  const handleAction = (id: string, isAction: boolean) => {
    const sectionOnPress = get(attributes, `${id}.action`, {});
    if (groupActionId && isNil(isAction)) {
      if (isEmpty(sectionOnPress)) {
        onPress && onPress(undefined, { groupActionId });
      } else {
        onPress && onPress(id, { groupActionId });
      }
    } else {
      onPress && onPress(id);
    }
  };

  const handleChangeCursor = (value: string, name: string) => {
    setCursor((prev) => {
      switch (name) {
        case CARD.CARD_NAME:
          return {
            ...prev,
            cursorCardName: value,
          };

        default:
          break;
      }
    });
  };

  useEffect(() => {
    if (Platform.OS === 'web') {
      const { cursorCardName } = cursor || {};

      const cardNameInput = cardNameRef?.current;
      const cardNumberInput = cardNumberRef?.current;

      if (cardNameInput)
        cardNameInput?.setSelectionRange(cursorCardName, cursorCardName);

      cardNumberInput?.addEventListener('input', (e: any) => {
        const field = e.target;
        let position = field.selectionEnd;
        const length = field.value.length;

        handleChange(field.value, CARD.CARD_NUMBER);

        field.selectionEnd =
          position +
          (field.value.charAt(position - 1) === ' ' &&
          field.value.charAt(length - 1) === ' '
            ? 1
            : 0);
      });
    }
  }, [cardNameRef, cardNumberRef, cursor.cursorCardName]);

  const handleCard = () => {
    try {
      setState((prev) => ({
        ...prev,
        loadingButtonCard: true,
      }));

      setState((prev) => ({ ...prev, isSaveCard: !prev.isSaveCard }));
    } catch (error) {
      console.log('error', error);
    } finally {
      setState((prev) => ({ ...prev, loadingButtonCard: false }));
    }
  };

  return {
    ...props,
    state,
    card,
    uriImage,
    isDisableButton: disableBtn,
    attributes,
    checkTypeCard,
    handleChange,
    handleSubmit,
    cardNameRef,
    cardNumberRef,
    cursor,
    handleChangeCursor,
    handleCard,
    toggleSaveCard,
    submitButtonText,
    onlySaveCard,
  };
};

export type Props = ReturnType<typeof useUnivaPay>;

export default useUnivaPay;
