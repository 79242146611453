import { StyleSheet, Platform } from 'react-native';
import { checkStyles } from '../func';

const createStyles = ({
  attrs,
  width,
  disabledButtonSubmit,
  isError,
  onlySaveCard,
  recurring,
}: {
  attrs: Record<string, any>;
  width: number;
  disabledButtonSubmit: boolean;
  isError: boolean;
  onlySaveCard: boolean;
  recurring: Record<string, any> | null;
}) => {
  const { opacity, titleCardName, titleCardNumber, submitButton } = attrs;

  return StyleSheet.create({
    container: {
      width,
      opacity,
    },

    titleCardName: {
      marginBottom: 6,
      ...checkStyles(titleCardName),
    },

    titleCardNumber: {
      marginBottom: 6,
      ...checkStyles(titleCardNumber),
    },

    inputCardName: {
      padding: 10,
      borderWidth: 1,
      borderRadius: 5,
      borderColor: 'rgb(189, 189, 189)',
      marginBottom: 10,
      // opacity: isSaveCard ? 0.6 : 1,
    },

    inputCardContainer: {
      // display: 'flex',
      flexDirection: 'row',
      justifyContent: !onlySaveCard && recurring ? 'center' : 'space-between',
      alignItems: 'center',
      padding: 10,
      borderWidth: 1,
      borderRadius: 5,
      borderColor:
        !onlySaveCard && recurring ? 'transparent' : 'rgb(189, 189, 189)',
      // opacity: isSaveCard ? 0.6 : 1,
    },

    inputCardImage: {
      // flex: 0.2,
      paddingRight: 8,
    },

    cardImage: { width: 21, height: 14 },

    inputCardNumber: {
      ...(!onlySaveCard && recurring ? { width: 120 } : { flex: 1 }),
      paddingRight: 8,
    },

    inputCardExpiration: {
      flex: 0.4,
      paddingRight: 8,
      ...(Platform.OS !== 'android' && { maxWidth: 62 }),
    },

    inputCardCVC: {
      flex: 0.3,
      maxWidth: 40,
    },

    input: {
      fontSize: 14,
      ...(Platform.OS === 'android' && { paddingVertical: 0 }),
      ...(Platform.OS === 'web' && {
        outlineStyle: 'none',
        outlineColor: 'transparent',
        placeholderTextColor: 'rgb(189, 189, 189)',
      }),
    },

    text: {
      paddingVertical: 8,
      color: isError ? '#ff0000' : '#008000',
    },

    btnCard: {
      marginTop: 20,
    },

    btn: {
      minHeight: 44,
      justifyContent: 'center',
      ...(Platform.OS === 'web' && {
        outlineColor: 'red',
      }),
      ...checkStyles(submitButton),
      flex: 1,
    },

    btnSubmit: {
      opacity: disabledButtonSubmit ? 0.6 : 1,
    },

    checkboxWrapper: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },

    checkboxInner: {
      flexDirection: 'row',
      alignItems: 'center',
    },

    labelCheckbox: {
      fontSize: 16,
    },

    disabled: {
      opacity: recurring ? 0.6 : 1,
    },
  });
};

export default createStyles;
