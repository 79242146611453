import React from 'react';
import { Button as RNButton, Checkbox as RNCheckbox } from 'react-native-paper';
import {
  Text,
  View,
  Image,
  TextInput,
  TouchableOpacity,
  Platform,
} from 'react-native';
import { Props } from './hook';
import createStyles from './style';
import { CARD, labelStyleUnivaPay, STATUS_UNIVAPAY } from './constant';
import { parseText, checkFont } from '../func';
import queryString from 'query-string';
import { URI_IMAGE_CARD } from '../../utils/cardPayment';

const UnivaPayForm: React.FC<Props> = (props) => {
  const {
    state,

    isDisableButton,
    card,
    uriImage,
    attributes,
    checkTypeCard,
    handleChange,
    handleSubmit,
    cardNameRef,
    cardNumberRef,
    cursor,
    handleChangeCursor,
    handleCard,
    toggleSaveCard,
    submitButtonText,
    onlySaveCard,
  } = props;

  const { width, titleCardName, titleCardNumber, submitButton } = attributes;

  const search = queryString.parse(window?.location?.search);
  const target = search?.target;
  const {
    enabled: titleCardNameEnabled = true,
    text: titleCardNameText = 'Card information',
  } = titleCardName || {};
  const {
    enabled: titleCardNumberEnabled = true,
    text: titleCardNumberText = 'Card information',
  } = titleCardNumber || {};

  const {
    cardName,
    cardNumber,
    cardExpiration,
    cardCvc,
    text: { status, message },
  } = card;

  const { loading, isSaveCard, recurring } = state;

  const styles = createStyles({
    attrs: attributes,
    width,
    disabledButtonSubmit: isDisableButton,
    isError: status !== STATUS_UNIVAPAY.SUCCESSFUL,
    onlySaveCard,
    recurring,
  });

  const {
    lengths,
    code: { name: nameCvc, size: sizeCvc },
  } = checkTypeCard || {};

  const { cursorCardName } = cursor || {};

  const maxLengthCardNumber = Math.max(...lengths);

  if (recurring && !onlySaveCard) {
    return (
      <View style={styles.container}>
        <View style={styles.inputCardContainer}>
          <View style={styles.inputCardImage}>
            <Image
              style={styles.cardImage}
              source={{
                uri:
                  URI_IMAGE_CARD[
                    recurring?.data_card.brand as URI_IMAGE_CARD
                  ] || URI_IMAGE_CARD['default'],
              }}
              resizeMode="cover"
            />
          </View>
          <TouchableOpacity activeOpacity={1} style={styles.inputCardNumber}>
            <View style={[styles.inputCardNumber, styles.disabled]}>
              <TextInput
                placeholder="Card number"
                style={styles.input}
                value={cardNumber}
                {...(Platform.OS === 'web'
                  ? { ref: cardNumberRef }
                  : {
                      onChangeText: (text) =>
                        handleChange(text, CARD.CARD_NUMBER),
                    })}
                maxLength={maxLengthCardNumber}
                keyboardType="number-pad"
                autoCompleteType="cc-number"
                ref={cardNumberRef}
                editable={false}
                selectTextOnFocus={false}
              />
            </View>
          </TouchableOpacity>
          {/* <TouchableOpacity activeOpacity={1}>
            <View style={[styles.inputCardExpiration, styles.disabled]}>
              <TextInput
                placeholder="MM / YY"
                style={styles.input}
                value={cardExpiration}
                onChangeText={(text) =>
                  handleChange(text, CARD.CARD_EXPIRATION)
                }
                maxLength={5}
                keyboardType="number-pad"
                autoCompleteType="cc-exp"
                editable={false}
                selectTextOnFocus={false}
              />
            </View>
          </TouchableOpacity>
          <TouchableOpacity activeOpacity={1}>
            <View style={[styles.inputCardCVC, styles.disabled]}>
              <TextInput
                placeholder={nameCvc}
                style={styles.input}
                value={cardCvc}
                onChangeText={(text) => handleChange(text, CARD.CARD_CVC)}
                maxLength={sizeCvc}
                keyboardType="number-pad"
                autoCompleteType="cc-csc"
                editable={false}
                selectTextOnFocus={false}
              />
            </View>
          </TouchableOpacity> */}
        </View>
        {!!status && !!message && <Text style={styles.text}>{message}</Text>}
        <View style={styles.btnCard}>
          <RNButton
            style={[styles.btn, styles.btnSubmit]}
            loading={loading}
            disabled={isDisableButton}
            onPress={handleSubmit}
            children={parseText(submitButtonText).toUpperCase()}
            labelStyle={labelStyleUnivaPay(attributes, isDisableButton, target)}
          />
        </View>
      </View>
    );
  }

  return (
    <View style={styles.container}>
      {titleCardNameEnabled && (
        <Text style={styles.titleCardName}>{parseText(titleCardNameText)}</Text>
      )}
      <TouchableOpacity activeOpacity={1}>
        <View style={styles.inputCardName}>
          <TextInput
            placeholder="Card holder name"
            style={styles.input}
            value={cardName}
            onChangeText={(text) => handleChange(text, CARD.CARD_NAME)}
            maxLength={30}
            autoCompleteType="name"
            {...(Platform.OS === 'web' && {
              onChange: (e: any) => {
                handleChangeCursor(e.target.selectionStart, CARD.CARD_NAME);
              },
              onFocus: (e: any) => {
                e.target.selectionStart = cursorCardName;
              },
              ref: cardNameRef,
            })}
            // {...(isSaveCard && { editable: false, selectTextOnFocus: false })}
          />
        </View>
      </TouchableOpacity>
      {titleCardNumberEnabled && (
        <Text style={styles.titleCardNumber}>
          {parseText(titleCardNumberText)}
        </Text>
      )}
      <View style={styles.inputCardContainer}>
        <View style={styles.inputCardImage}>
          <Image
            style={styles.cardImage}
            source={{ uri: uriImage }}
            resizeMode="cover"
          />
        </View>
        <TouchableOpacity activeOpacity={1} style={styles.inputCardNumber}>
          <View style={styles.inputCardNumber}>
            <TextInput
              placeholder="Card number"
              style={styles.input}
              value={cardNumber}
              {...(Platform.OS === 'web'
                ? { ref: cardNumberRef }
                : {
                    onChangeText: (text) =>
                      handleChange(text, CARD.CARD_NUMBER),
                  })}
              maxLength={maxLengthCardNumber}
              keyboardType="number-pad"
              autoCompleteType="cc-number"
              ref={cardNumberRef}
              // {...(isSaveCard && { editable: false, selectTextOnFocus: false })}
            />
          </View>
        </TouchableOpacity>
        <TouchableOpacity activeOpacity={1}>
          <View style={styles.inputCardExpiration}>
            <TextInput
              placeholder="MM / YY"
              style={styles.input}
              value={cardExpiration}
              onChangeText={(text) => handleChange(text, CARD.CARD_EXPIRATION)}
              maxLength={5}
              keyboardType="number-pad"
              autoCompleteType="cc-exp"
              // {...(isSaveCard && { editable: false, selectTextOnFocus: false })}
            />
          </View>
        </TouchableOpacity>
        <TouchableOpacity activeOpacity={1}>
          <View style={styles.inputCardCVC}>
            <TextInput
              placeholder={nameCvc}
              style={styles.input}
              value={cardCvc}
              onChangeText={(text) => handleChange(text, CARD.CARD_CVC)}
              maxLength={sizeCvc}
              keyboardType="number-pad"
              autoCompleteType="cc-csc"
              // {...(isSaveCard && { editable: false, selectTextOnFocus: false })}
            />
          </View>
        </TouchableOpacity>
      </View>
      {!onlySaveCard && (
        <View style={styles.checkboxWrapper}>
          <TouchableOpacity onPress={toggleSaveCard}>
            <View style={styles.checkboxInner}>
              <RNCheckbox
                status={isSaveCard ? 'checked' : 'unchecked'}
                color={'#4a4a4a'}
              />
              <Text style={styles.labelCheckbox}>{'Save card'}</Text>
            </View>
          </TouchableOpacity>
        </View>
      )}
      {!!status && !!message && <Text style={styles.text}>{message}</Text>}
      <View style={styles.btnCard}>
        <RNButton
          style={[styles.btn, styles.btnSubmit]}
          loading={loading}
          disabled={isDisableButton}
          onPress={handleSubmit}
          children={parseText(submitButtonText).toUpperCase()}
          labelStyle={labelStyleUnivaPay(attributes, isDisableButton, target)}
        />
      </View>
    </View>
  );
};

export default UnivaPayForm;
