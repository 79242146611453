import { IRecord } from '@common/types';
// import { ILibraryComponent } from '@common/types/element';
import {
  BINDING_SELECTOR_TYPE,
  componentName,
  pathComponents,
} from '@common/constants/shared';
import { useBinding } from '@common/hooks/useBinding';
import store from '@common/redux/store';
import { bindingType } from '@common/utils/functions';
import {
  parsedDateBinding,
  parsedItemDateBinding,
} from '@common/utils/handleBinding/function';
import {
  formatPluginDataSource,
  isPluginDataBinding,
} from '@common/utils/plugin';
import { isString } from 'formik';
import { cloneDeep, find, get, includes, isEmpty, omit, set } from 'lodash';
import { getImageMarker, getTextBinding } from '@common/utils/handleBinding';

export const handleBinding = (
  // obj: ILibraryComponent,
  obj: any,
  handleBindingField: (
    value: any,
    record: any,
    index?: number | undefined
  ) => any,
  handleBindingList: (
    records: IRecord[],
    attrs: any,
    parentRecord?: Record<string, any>
  ) => any
) => {
  const state: any = store.getState();

  const attrChild = obj.attributes;
  const authProfile = state.auth.profile;

  if (obj.isPlugin) {
    const props: any = {};
    const records = obj.records || [];
    const pluginProps: any = [];
    const childComponentsKeys: any = {};
    const getTableId = get(obj, 'selectedItem.tableId');

    const pluginPropsTemp: any[] = obj?.libraryComponentManifest?.props || [];
    const pluginChildComponents: any[] =
      obj?.libraryComponentManifest?.childComponents || [];

    // push binding from pluginProps to pluginChildComponents
    for (let i = 0; i < pluginPropsTemp.length; i++) {
      const prop = pluginPropsTemp[i];
      if (isPluginDataBinding(prop)) {
        pluginChildComponents.push({
          name: prop.name,
          isPluginDataBinding: true,
        });
      } else {
        pluginProps.push(prop);
      }
    }

    // binding childComponents
    [...pluginChildComponents].forEach((prop) => {
      const parentPropKey = prop.name;

      if (prop.isPluginDataBinding) {
        childComponentsKeys[parentPropKey] = attrChild[parentPropKey];
      }

      prop?.props &&
        [...prop?.props].forEach((propComponent) => {
          const childrenPropKey = propComponent.name;
          const attrChildKey = attrChild[parentPropKey]?.[childrenPropKey];

          // push pluginProp to pluginProps
          if (!isPluginDataBinding(prop)) {
            pluginProps.push({
              name: `${parentPropKey}.${childrenPropKey}`,
            });
            attrChild[`${parentPropKey}.${childrenPropKey}`] = attrChildKey;
            return;
          }

          childComponentsKeys[`${parentPropKey}.${childrenPropKey}`] = '';

          if (attrChildKey) {
            childComponentsKeys[`${parentPropKey}.${childrenPropKey}`] =
              attrChildKey;
          }
        });
    });

    const dataBinding = isEmpty(childComponentsKeys)
      ? []
      : records.map((record: any) => {
          const bindingItem = {};
          set(bindingItem, '_id', record._id);
          for (const key in childComponentsKeys) {
            if (
              Object.prototype.hasOwnProperty.call(childComponentsKeys, key)
            ) {
              const dataSource = formatPluginDataSource(
                cloneDeep(childComponentsKeys[key])
              );

              const element = handleBindingField(dataSource, record);

              set(bindingItem, key, element);
            }
          }
          return bindingItem;
        });

    // binding props
    [...pluginProps].forEach((prop) => {
      const propName = prop.name;

      const dataSource = formatPluginDataSource(cloneDeep(attrChild[propName]));

      props[propName] = handleBindingField(
        dataSource,
        obj?.record || state.database.currentRecord[getTableId] || {}
      );
    });

    return {
      ...obj,
      attributes: { ...attrChild, ...props },
      dataBinding,
    };
  }

  switch (obj.componentName) {
    case componentName.CALENDAR:
      const records = obj.records || [];
      const items = handleBindingList(records, attrChild).map((item: any) => {
        return {
          ...item,
          agenda: {
            ...item.agenda,
            eventStarttime: parsedItemDateBinding({
              value: item.agenda.eventStarttime,
            }),
            eventEndtime: parsedItemDateBinding({
              value: item.agenda.eventEndtime,
            }),
          },
        };
      });

      const attDefDate = get(attrChild, 'navigation.defDate');
      const defDate = parsedDateBinding(
        attDefDate,
        handleBindingField,
        new Date()
      );

      const attMinDate = get(attrChild, 'navigation.minDate');
      const minDate = parsedDateBinding(
        attMinDate,
        handleBindingField,
        '2019-01-01'
      );

      const attMaxDate = get(attrChild, 'navigation.maxDate');
      const maxDate = parsedDateBinding(
        attMaxDate,
        handleBindingField,
        '2030-01-01'
      );

      return {
        ...obj,
        attributes: {
          ...attrChild,
          items,
          navigation: {
            ...attrChild.navigation,
            defDate,
            minDate,
            maxDate,
          },
        },
      };
    case componentName.SIMPLE_LIST:
    case componentName.CARD_LIST:
    case componentName.CAROUSEL:
    case componentName.HORIZONTAL_CARD_LIST:
    case componentName.CHIP_LIST:
    case componentName.AVATAR_LIST: {
      let records: IRecord[] = obj.records || [];
      return {
        ...obj,
        dataBinding: handleBindingList(records, { ...attrChild }, obj.record),
      };
    }

    case componentName.MAP: {
      const markerType = attrChild.markerType as 'simple' | 'multiple';

      let records: any[] = obj.records || [];

      if (markerType === 'simple') {
        const marker = attrChild?.marker;
        const markerTypeOfPin = marker?.markerTypeOfPin;
        const imageMarkerBinding = getImageMarker(
          obj.attributes,
          null,
          'simple'
        );
        const imageMarker =
          typeof imageMarkerBinding !== 'string'
            ? imageMarkerBinding?.binding
            : imageMarkerBinding;

        if (markerTypeOfPin !== 'geometry') {
          return {
            ...obj,
            dataBinding: [
              {
                markerAddress: handleBindingField(
                  get(attrChild, 'marker.markerAddress'),
                  obj.record || state.database.currentRecord
                ),
                imageMarker: imageMarker,
              },
            ],
          };
        } else {
          const lng = handleBindingField(
            get(marker, 'markerX', null),
            obj.record || state.database.currentRecord
          );
          const lat = handleBindingField(
            get(marker, 'markerY', null),
            obj.record || state.database.currentRecord
          );
          const isShowLocation = !!lat && !!lng;

          return {
            ...obj,
            dataBinding: isShowLocation
              ? [
                  {
                    lng,
                    lat,
                    imageMarker: imageMarker,
                  },
                ]
              : [],
          };
        }
      } else {
        const objRecords = obj.record
          ? records.map((record: IRecord) => {
              return {
                ...record,
                currentParent: {
                  ...obj.record,
                  databaseUuid: obj?.parentListDatabaseUuid,
                },
              };
            })
          : records;

        const selectedAddressSource = get(
          obj?.attributes,
          pathComponents.selectedAddressMarker,
          undefined
        );

        const selectedAddressMarker = getTextBinding(
          selectedAddressSource,
          null
        );

        return {
          ...obj,
          dataBinding:
            objRecords.length <= 0
              ? [{ selectedAddressMarker }]
              : handleBindingList(objRecords, attrChild),
        };
      }
    }

    case componentName.SIGNATURE_PAD: {
      const getTableId = get(obj, 'selectedItem.tabeId');
      return {
        ...obj,
        attributes: {
          ...attrChild,
          clearButton: {
            ...attrChild.clearButton,
            text: handleBindingField(
              get(attrChild, 'clearButton.text'),
              obj?.record || state.database.currentRecord[getTableId]
            ),
          },
          saveButton: {
            ...attrChild.saveButton,
            text: handleBindingField(
              get(attrChild, 'saveButton.text'),
              obj?.record || state.database.currentRecord[getTableId]
            ),
          },
        },
      };
    }

    case componentName.STRIPE_PAYMENT: {
      const getTableId = get(obj, 'selectedItem.tableId');
      return {
        ...obj,
        attributes: {
          ...attrChild,
          changedescription: {
            description: handleBindingField(
              get(attrChild, 'changedescription.description'),
              obj?.record || state.database.currentRecord[getTableId]
            ),
          },
          email: {
            emailBuyer: handleBindingField(
              get(attrChild, 'email.emailBuyer'),
              obj?.record || state.database.currentRecord[getTableId]
            ),
          },
          paymentOptions: {
            paymentAmount: handleBindingField(
              get(attrChild, 'paymentOptions.paymentAmount'),
              obj?.record || state.database.currentRecord[getTableId]
            ),
            typeCurrency: get(attrChild, 'paymentOptions.typeCurrency'),
          },
          testMode: {
            ...get(attrChild, 'testMode'),
            publishableKey: handleBindingField(
              get(attrChild, 'testMode.publishableKey'),
              {}
            ),
            secretKey: handleBindingField(
              get(attrChild, 'testMode.secretKey'),
              {}
            ),
          },
          submitButton: {
            ...get(attrChild, 'submitButton'),
            text: handleBindingField(
              get(attrChild, 'submitButton.text'),
              obj?.record || state.database.currentRecord[getTableId]
            ),
          },
        },
      };
    }

    case componentName.STRIPE_SUBSCRIPTION: {
      const getTableId = get(obj, 'selectedItem.tableId');
      return {
        ...obj,
        attributes: {
          ...attrChild,
          subscription: {
            priceId: handleBindingField(
              get(attrChild, 'subscription.priceId'),
              obj?.record || state.database.currentRecord[getTableId]
            ),
            quantity: get(attrChild, 'subscription.quantity'),
          },
          email: {
            emailBuyer: handleBindingField(
              get(attrChild, 'email.emailBuyer'),
              obj?.record || state.database.currentRecord[getTableId]
            ),
          },
          testMode: {
            ...get(attrChild, 'testMode'),
            publishableKey: handleBindingField(
              get(attrChild, 'testMode.publishableKey'),
              {}
            ),
            secretKey: handleBindingField(
              get(attrChild, 'testMode.secretKey'),
              {}
            ),
          },
          submitButton: {
            ...get(attrChild, 'submitButton'),
            text: handleBindingField(
              get(attrChild, 'submitButton.text'),
              obj?.record || state.database.currentRecord[getTableId]
            ),
          },
        },
      };
    }

    case componentName.UNIVAPAY:
    case componentName.UNIVAPAY_SUBSCRIPTION: {
      const getTableId = get(obj, 'selectedItem.tableId');
      return {
        ...obj,
        attributes: {
          ...attrChild,
          email: {
            emailBuyer: handleBindingField(
              get(attrChild, 'email.emailBuyer'),
              obj?.record || state.database.currentRecord[getTableId]
            ),
          },
          paymentOptions: {
            paymentAmount: handleBindingField(
              get(attrChild, 'paymentOptions.paymentAmount'),
              obj?.record || state.database.currentRecord[getTableId]
            ),
            typeCurrency: get(attrChild, 'paymentOptions.typeCurrency'),
            period: get(attrChild, 'paymentOptions.period'),
          },
          titleCardName: {
            ...get(attrChild, 'titleCardName'),
            text: handleBindingField(
              get(attrChild, 'titleCardName.text'),
              obj?.record || state.database.currentRecord[getTableId]
            ),
          },
          titleCardNumber: {
            ...get(attrChild, 'titleCardNumber'),
            text: handleBindingField(
              get(attrChild, 'titleCardNumber.text'),
              obj?.record || state.database.currentRecord[getTableId]
            ),
          },
          submitButton: {
            ...get(attrChild, 'submitButton'),
            text: handleBindingField(
              get(attrChild, 'submitButton.text'),
              obj?.record || state.database.currentRecord[getTableId]
            ),
          },
          saveCardOptions: {
            ...get(attrChild, 'saveCardOptions'),
            saveCardMode: handleBindingField(
              get(attrChild, 'saveCardOptions.saveCardMode'),
              obj?.record || state.database.currentRecord[getTableId]
            ),
          },
        },
      };
    }

    case componentName.TAB_NAVIGATOR:
      const getLabel = (value: any) => {
        let text;

        if (!isString(value?.label)) {
          text = handleBindingField(value?.label, {});
        } else {
          text = value?.label;
        }

        return { ...value, label: text };
      };

      return {
        ...obj,
        attributes: {
          ...attrChild,
          tab0: getLabel(attrChild?.tab0),
          tab1: getLabel(attrChild?.tab1),
          tab2: getLabel(attrChild?.tab2),
          tab3: getLabel(attrChild?.tab3),
          tab4: getLabel(attrChild?.tab4),
        },
      };

    case componentName.TOGGLE:
    case componentName.SWITCH:
      let newAttrChild: Record<string, any> = {};

      if (obj.componentName === componentName.SWITCH) {
        newAttrChild = {
          ...omit(attrChild, ['value']),
          input: attrChild.value,
        };
      } else {
        newAttrChild = attrChild;
      }

      return setAttributesComponent(newAttrChild, obj, authProfile);

    case componentName.BUTTON:
    case componentName.BARCODE:
      const getTableId = get(obj, 'selectedItem.tableId');
      const getItemIndex = get(obj, 'selectedItem.itemIndex');

      const getText = handleBindingField(
        attrChild?.text?.text || attrChild?.text || attrChild?.value,
        obj?.record || state.database.currentRecord[getTableId],
        getItemIndex
      );

      return {
        ...obj,
        attributes: {
          ...attrChild,
          text: getText,
        },
      };

    default:
      let text;

      if (!isString(attrChild?.text)) {
        text = handleBindingField(attrChild?.text?.text || attrChild?.text, {});
      } else {
        text = attrChild?.text;
      }

      return { ...obj, attributes: { ...attrChild, text: text } };
  }
};

const setAttributesComponent = (
  attrChild: Record<string, any>,
  obj: Record<string, any>,
  authProfile: Record<string, any>
) => {
  const state: any = store.getState();
  const currentRecord = state.database.currentRecord;
  const dataSource = state.database.dataSource;

  const getSource = get(attrChild, 'input.source');

  const getSelector = get(getSource, 'source.selector.type');
  const typeRelationship = get(getSource, 'source.type');
  const sourceRelationship = get(getSource, 'source.source.selector.type');
  const getFieldId = get(getSource, 'source.fieldId');

  const getDataSourceAuth = () => {
    const getData = get(dataSource, authProfile?.databaseId, []);

    return getData.find(
      (item: Record<string, any>) =>
        item.databaseId === authProfile?.databaseId &&
        item._id === authProfile?.userId
    );
  };

  // === BE_LONG_RELATIONSHIP ===
  if (typeRelationship === bindingType.BE_LONG_RELATIONSHIP) {
    // === ROUTE_PARAM_SELECTOR ===
    if (sourceRelationship === BINDING_SELECTOR_TYPE.ROUTE_PARAM_SELECTOR) {
      const getTable = get(dataSource, getSource.source.source.tableId);

      let isExits: boolean = false;

      if (obj?.record) {
        for (let index = 0; index < getTable.length; index++) {
          const result = get(obj, `record.${getFieldId}`);

          if (result && result.includes(getTable[index]._id)) {
            isExits = get(obj, `record.${getSource.fieldId}`);
          }
        }
      } else {
        const result = get(dataSource, getSource.source.tableId).find(
          (item: any) => {
            return (
              item._id ===
              get(
                currentRecord,
                `${getSource.source.source.tableId}.record.${getSource.source.fieldId}`
              )
            );
          }
        );

        isExits = get(result, `record.${getSource.fieldId}`);
      }

      return {
        ...obj,
        attributes: {
          ...attrChild,
          value: isExits,
        },
      };
    }

    // === CURRENT_USER_SELECTOR ===
    if (sourceRelationship === BINDING_SELECTOR_TYPE.CURRENT_USER_SELECTOR) {
      if (getSource?.dataType === 'boolean') {
        const getTableToTarget = get(dataSource, getSource?.source?.tableId);

        const findItem =
          find(getTableToTarget, (item) =>
            (item?.record[getFieldId] || []).includes(authProfile?.userId)
          ) || null;

        const isExits = isEmpty(findItem)
          ? null
          : includes(
              get(findItem, `record.${getFieldId}`),
              authProfile?.userId
            );

        return {
          ...obj,
          attributes: {
            ...attrChild,
            value: isExits
              ? get(findItem, `record.${getSource?.fieldId}`)
              : false,
          },
        };
      }

      const getCurrentRecord = get(
        currentRecord,
        `${getSource?.source?.source?.tableId}`,
        {}
      );
      const currentValue = get(getCurrentRecord.record, getFieldId, []);
      const isExist = includes(currentValue, authProfile?.userId);

      return {
        ...obj,
        attributes: {
          ...attrChild,
          value: isExist ? getCurrentRecord.record[getSource?.fieldId] : null,
        },
      };
    }
  }

  // === MANY_TO_MANY_RELATIONSHIP ===
  if (typeRelationship === bindingType.MANY_TO_MANY_RELATIONSHIP) {
    // === CURRENT_USER_SELECTOR ===
    if (sourceRelationship === BINDING_SELECTOR_TYPE.CURRENT_USER_SELECTOR) {
      let isExist: boolean | null = false;
      const dataAuth = getDataSourceAuth();

      if (
        getSource?.value?.selector.type ===
        BINDING_SELECTOR_TYPE.LIST_ITEM_SELECTOR
      ) {
        const resultValue = get(getSource, 'value.fieldId');

        isExist = includes(
          get(dataAuth, `record.${getFieldId}`),
          get(obj, `record.${resultValue ? resultValue : '_id'}`)
        );
      } else if (
        getSource?.value?.selector.type ===
        BINDING_SELECTOR_TYPE.CURRENT_USER_SELECTOR
      ) {
        const getField = get(dataAuth, `record.${getFieldId}`);

        isExist = includes(
          getField,
          get(obj, `record.${getSource.value.fieldId}`)
        );
      } else {
        const getCurrentRecord = get(
          currentRecord,
          `${getSource?.source?.tableId}`,
          {}
        );

        const currentValue = get(
          getCurrentRecord.record ? getCurrentRecord.record : obj.record,
          getFieldId,
          []
        );

        isExist = includes(currentValue, get(authProfile, 'userId'));
      }

      return {
        ...obj,
        attributes: {
          ...attrChild,
          value: isExist,
        },
      };
    }

    // === LIST_ITEM_SELECTOR ===
    if (sourceRelationship === BINDING_SELECTOR_TYPE.LIST_ITEM_SELECTOR) {
      const getCurrentRecord = get(
        currentRecord,
        `${getSource?.source?.tableId}`,
        {}
      );

      const currentValue = get(obj.record, getFieldId, []);

      const isAuth =
        getSource?.value?.selector?.type ===
        BINDING_SELECTOR_TYPE.CURRENT_USER_SELECTOR
          ? authProfile?.userId
          : getCurrentRecord._id;

      const isExits = includes(currentValue, isAuth);

      return {
        ...obj,
        attributes: {
          ...attrChild,
          value: isExits,
        },
      };
    }

    // === ROUTE_PARAM_SELECTOR ===
    if (sourceRelationship === BINDING_SELECTOR_TYPE.ROUTE_PARAM_SELECTOR) {
      const getCurrentRecord = get(
        currentRecord,
        `${getSource?.source?.source?.tableId}`,
        {}
      );

      const currentValue = get(
        getCurrentRecord.record ? getCurrentRecord.record : obj.record,
        getFieldId,
        []
      );

      const isAuth =
        getSource?.value?.selector?.type ===
        BINDING_SELECTOR_TYPE.CURRENT_USER_SELECTOR
          ? authProfile?.userId
          : getSource?.value?.selector?.type ===
            BINDING_SELECTOR_TYPE.LIST_ITEM_SELECTOR
          ? get(obj, 'record._id', '')
          : getCurrentRecord._id;

      const isExits = includes(currentValue, isAuth);

      return {
        ...obj,
        attributes: {
          ...attrChild,
          value: isExits,
        },
      };
    }
  }

  //Binding Data
  if (getSelector === BINDING_SELECTOR_TYPE.CURRENT_USER_SELECTOR) {
    return {
      ...obj,
      attributes: {
        ...attrChild,
        value: !isEmpty(authProfile)
          ? authProfile[getSource.fieldId]
          : attrChild.value,
      },
    };
  } else if (getSelector === BINDING_SELECTOR_TYPE.ROUTE_PARAM_SELECTOR) {
    const getTable = get(currentRecord, getSource?.source?.tableId);

    return {
      ...obj,
      attributes: {
        ...attrChild,
        value: !isEmpty(getTable)
          ? get(getTable, `record.${getSource?.fieldId}`)
          : attrChild.value,
      },
    };
  } else if (getSelector === BINDING_SELECTOR_TYPE.LIST_ITEM_SELECTOR) {
    const { handleBindingField } = useBinding();

    const result = handleBindingField(attrChild.input, obj?.record);

    return {
      ...obj,
      attributes: {
        ...attrChild,
        value: result,
      },
    };
  }

  return {
    ...obj,
    attributes: {
      ...attrChild,
    },
  };
};

export type Header = {
  key: string;
  value: string;
};

export const setParamsAndHeaders = (headersAndParamsAuth: any) => {
  const paramArr: Header[] = [];
  const header = {};
  headersAndParamsAuth.forEach((element: any) => {
    const key = element.name;
    const value = element.value;
    if (element.type === 'Param') {
      paramArr.push({
        key: key,
        value: value,
      });
    } else {
      Object.assign(header, { [key]: value });
    }
  });
  return { paramArr, header };
};
