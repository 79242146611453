import useAvailability from '@common/hooks/useVisibility';
import { getValueFields } from '@common/redux/selectors/formInput';
import { actionPromise } from '@common/utils/handleActions/excuteAction';
import {
  find,
  get,
  isNil,
  pick,
  isEmpty,
  isArray,
  includes,
  omit,
} from 'lodash';
import React, { FC, Fragment, useEffect, useState } from 'react';
import { View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import {
  databaseSelector,
  getAuth,
  getCurrentRecord,
  getDataSourceStore,
  getListDatabases,
} from '@common/redux/selectors/database';
import {
  getLocaleApp,
  getSortAndFilterData,
  getSortCondition,
  SortAndFilterType,
  SortConditionType,
} from '@common/utils/functions';
import useFilter from '@common/hooks/useFilter';
import {
  getParentListRecordComponent,
  getRecordExternalCollection,
  getRecords,
} from '@common/utils/database';
import { updateExternal } from '@common/redux/slice/externalCollection';
import Loading from '@common/components/Loading';
import { IRecord } from '@common/types/database';
import BindingNewComponent from './BindingNewComponent';
import { paginate } from '@common/utils/handleBinding/function';
import { useRefreshTable } from '@common/hooks/databaseListener/automaticList';
import ActionWrapper from '@common/screens/LoadingAction';
import { IUseFilterType } from '@common/types/';

type Props = {
  keyItem?: number;
  ObjectClass: FC;
  obj: any;
  parentRecord?: IRecord;
};

type BindingDataProps = {
  keyItem?: number;
  ObjectClass: FC;
  obj: any;
  databaseUuid: string;
  onPress?: (actionRef: string, options?: { itemId: string }) => void;
  loading?: boolean;
  loadingAction?: boolean;
  parentRecord?: IRecord;
};

const isShowLoadingAction = [
  'label',
  'image',
  'section',
  'Icon',
  'ActionButton',
  'Button',
];

const BindingComponent = ({
  ObjectClass,
  obj,
  keyItem,
  parentRecord,
}: Props) => {
  const isNewList = ['HorizontalCardList', 'AvatarList', 'ChipList'].includes(
    obj.componentName
  );

  const [loadingAction, setLoadingAction] = useState<boolean>(false);
  const currentRecord = useSelector(getCurrentRecord) || {};

  const locale = getLocaleApp();

  const visibilityConfig =
    !isEmpty(obj?.visibilities) && isArray(obj?.visibilities)
      ? obj?.visibilities
      : obj.visibility;

  const isDisplay = useAvailability(
    visibilityConfig,
    obj?.record || currentRecord,
    getParentListRecordComponent(obj)
  );
  const databaseUuid =
    obj?.dataBinding?.tableId ||
    obj?.dataBinding?.source?.tableId ||
    obj?.attributes?.items?.source?.tableId ||
    obj?.attributes?.database?.idCollectionSelected ||
    obj?.database?.source?.tableId ||
    obj?.attributes?.markerCollection?.source?.tableId;

  let refresh =
    get(obj, 'dataBinding.source.options.listenForChanges', false) ||
    get(obj, 'attributes.items.source.options.listenForChanges', false);

  useRefreshTable(databaseUuid, refresh);

  if (!isDisplay)
    return (
      <View
        style={{
          ...pick(obj, ['width', 'height', 'marginTop', 'marginLeft']),
        }}
        pointerEvents="none"
      />
    );

  if (!ObjectClass) return <Fragment />;

  const handlePress = async (
    actionRef: string,
    options?: {
      itemId?: string;
      groupActionId?: string;
      indexRecord?: number;
      externalId?: any;
      externalRecord?: any;
      payloadComponent?: Record<string, any>;
    }
  ) => {
    const { payloadComponent } = options || {};
    // get actions
    let arrayAction: Array<any> = [];

    const isObjList = includes(
      ['SimpleList', 'CardList', 'list'],
      obj.componentName
    );

    // check action ref

    if (
      (actionRef &&
        (obj.attributes[actionRef] ||
          obj.attributes[actionRef]?.action ||
          obj.attributes[actionRef]?.onPress)) ||
      obj.componentName === 'Table'
    ) {
      let attActionsId =
        obj.attributes[actionRef]?.actionId ||
        obj.attributes[actionRef]?.action?.actionId ||
        obj.attributes[actionRef]?.onPress?.actionId ||
        null;
      if (obj.componentName === 'Table') attActionsId = actionRef;
      const actionComponent = obj?.actions?.[attActionsId];

      arrayAction = actionComponent?.actions || [];
    } else {
      if (options?.groupActionId) {
        const actionComponent = obj?.actions?.[options?.groupActionId];

        arrayAction = actionComponent?.actions || [];
      } else {
        if (
          obj?.isInCustomList ||
          !isObjList ||
          obj?.componentName === 'list'
        ) {
          let actions = obj?.actions && Object.values(obj?.actions);
          if (
            actions?.length &&
            (!actionRef ||
              typeof actionRef === 'object' ||
              !isNil(obj.attributes[actionRef]))
          ) {
            arrayAction = actions[0].actions;
          }
        } else {
          if (isObjList) {
            let actions: Record<string, any>[] = obj?.actions;
            const actionId = obj?.attributes?.action?.actionId;
            if (
              !isEmpty(actions) &&
              !isNil(actions) &&
              !isNil(actionId) &&
              !isNil(actions[actionId])
            ) {
              arrayAction = actions[actionId].actions;
            }
          }
        }
      }
    }

    // get current item in list
    const recordId =
      options?.itemId || obj?.selectedItem?.itemId || obj?.record?._id;
    const itemTableId =
      obj?.selectedItem?.tableId || obj?.record?.databaseUuid || databaseUuid;

    let currentData: any = {};

    if (itemTableId && recordId && arrayAction.length) {
      const records: Record<string, any> = await getRecords(
        itemTableId || databaseUuid
      );

      if (records) {
        currentData = find(records, {
          _id: recordId,
        });
      }
    }

    if (obj.isInCustomList && !isObjList) {
      const records: Record<string, any> = await getRecords(
        obj.parentListDatabaseUuid
      );
      if (records) {
        const parentListItem = find(records, {
          _id: obj.parentListItemId,
        });
        currentData = parentListItem
          ? [parentListItem, currentData]
          : currentData;
      }
    }

    if (isObjList && obj.isInCustomList) {
      const records: Record<string, any> = await getRecords(databaseUuid);
      if (records) {
        const parentListItem = find(records, {
          _id: recordId,
        });
        currentData = parentListItem
          ? [
              {
                databaseId: itemTableId,
                record: obj.record,
                _id: obj.record?._id,
              },
              parentListItem,
            ]
          : {
              databaseId: itemTableId,
              record: obj.record,
              _id: obj.record?._id,
            };
      }
    }

    const itemIndex = get(obj, 'selectedItem.itemIndex');

    const externalId = get(obj, 'selectedItem.externalId');

    const externalIndex =
      itemIndex !== undefined ? itemIndex : options?.indexRecord;

    const externalUpdateId =
      externalId !== undefined ? externalId : options?.externalId;

    const externalRecord = {
      databaseUuid:
        obj.selectedItem?.tableId ||
        get(obj, 'attributes.items.source.tableId'),
      externalRecord: options?.externalRecord || obj?.record,
    };

    const parentRecordList = getParentListRecordComponent(obj);

    let currentRecord = currentData || obj?.record;

    if (isEmpty(currentRecord)) currentRecord = options?.externalRecord || {};

    // excute actions
    if (arrayAction.length) {
      setLoadingAction(true);
      await actionPromise(
        arrayAction,
        currentRecord,
        itemIndex,
        locale,
        undefined,
        externalIndex?.toString(),
        externalUpdateId,
        externalRecord,
        parentRecordList,
        undefined,
        payloadComponent
      ).finally(() => setLoadingAction(false));

      return arrayAction;
    }
  };

  if (loadingAction && isShowLoadingAction.includes(obj.componentName)) {
    return <ActionWrapper obj={obj} />;
  }

  if (databaseUuid) {
    if (isNewList) {
      return (
        <BindingNewComponent
          ObjectClass={ObjectClass}
          obj={obj}
          handlePress={handlePress}
          databaseUuid={databaseUuid}
          keyItem={keyItem}
          zIndex={obj.zIndex}
        />
      );
    }

    return (
      <BindingDataComponent
        ObjectClass={ObjectClass}
        obj={{
          ...obj,
          record: {
            ...obj.record,
            ...(parentRecord && { parentRecord }),
          },
        }}
        databaseUuid={databaseUuid}
        onPress={handlePress}
        loadingAction={loadingAction}
        keyItem={keyItem}
      />
    );
  }

  return (
    <ObjectClass
      {...{
        ...obj,
        loadingAction,
        record: {
          ...obj.record,
          ...(parentRecord && { parentRecord }),
        },
      }}
      onPress={handlePress}
      keyItem={keyItem}
    />
  );
};

export type FilterItemProp = {
  comparator: string;
  fieldId: string | object;
  id: string;
  comparison: any;
  comparison2?: any;
};

const BindingDataComponent = ({
  ObjectClass,
  obj,
  databaseUuid,
  onPress,
  keyItem,
  loadingAction,
}: BindingDataProps) => {
  const dataSource = useSelector(getDataSourceStore);
  const collections = useSelector(getListDatabases);
  const auth = useSelector(getAuth);
  const data = dataSource[databaseUuid];

  const ObjectBinding = useSelector(getValueFields);

  const { filter, limit, source }: SortAndFilterType =
    getSortAndFilterData(obj);

  let { sort }: SortConditionType = getSortCondition(obj);

  const collection: any = collections.find(
    (item: Record<string, any>) => item.databaseUuid === databaseUuid
  );

  const filterResponse: IUseFilterType = useFilter(data || [], source, {
    filter,
    sort,
    maximum: limit,
    ObjectBinding,
    databaseUuid,
    collection,
    auth,
    obj,
  });

  let records = filterResponse?.records || [];

  const { database } = useSelector(databaseSelector);

  const [loading, setLoading] = useState<boolean>(false);

  const [dataExternal, setDataExternal] = useState([]);

  const dispatch = useDispatch();

  const externalCollection = find(database, {
    databaseUuid,
  });

  // check change database
  const dependencyCheck = obj?.id + databaseUuid;

  useEffect(() => {
    const getDataExternal = async () => {
      if (databaseUuid) {
        if (externalCollection && externalCollection.isThirdParty) {
          setLoading(true);
          const metadata = JSON.parse(externalCollection.databaseMetadata)[0];
          if (metadata) {
            await getRecordExternalCollection(metadata, externalCollection)
              .then((value: any) => {
                if (value) {
                  const dataFilter = paginate(value, {
                    maximum: limit,
                    sort,
                    filterOptions: filter,
                    databaseUuid,
                    ObjectBinding,
                  });
                  setDataExternal(dataFilter);
                  dispatch(
                    updateExternal({
                      externalCollections: {
                        data: dataFilter,
                        databaseUuid: databaseUuid,
                        dataRoot: value,
                      },
                    })
                  );
                }
              })
              .finally(() => {
                setLoading(false);
              });
          }
        }
      }
    };
    getDataExternal();
  }, [dependencyCheck]);

  if (
    dataExternal?.length &&
    externalCollection &&
    externalCollection.isThirdParty
  ) {
    records = dataExternal;
  }

  const attrs = {
    ...obj,
    records,
    externalCollection,
    ...omit(filterResponse, 'records'),
    loadingAction,
  };

  return (
    <React.Fragment>
      <>
        {loading ? (
          <Loading />
        ) : (
          data && (
            <ObjectClass
              onPress={onPress}
              databaseUuid={databaseUuid}
              keyItem={keyItem}
              zIndex={obj.zIndex}
              {...attrs}
            />
          )
        )}
      </>
    </React.Fragment>
  );
};
export default BindingComponent;
