import { getMessage } from '../../utils/locale';
import { checkFont } from '../func';

export const CARD = {
  CARD_NAME: 'cardName',
  CARD_NUMBER: 'cardNumber',
  CARD_EXPIRATION: 'cardExpiration',
  CARD_CVC: 'cardCvc',
  TEXT_ERROR: 'textError',
};

export const STATUS_UNIVAPAY = {
  SUCCESSFUL: 'successful',
  FAILED: 'failed',
  ERROR: 'error',
  CANCELED: 'canceled',
};

export const SAVE_CARD_MODE = {
  SAVE_CARD_AND_PAYMENT: 'saveCardAndPayment',
  ONLY_SAVE_CARD: 'onlySaveCard',
};

export const getTextErrors = (textError = {}) => ({
  cardNameInvalid: 'This card holder name looks invalid',
  cardNumberInvalid: 'This card number looks invalid',
  cardExpirationInvalid: 'This expiration date looks invalid',
  cardCvcInvalid: 'This cvc date looks invalid',
  cardNameRequired: 'Card holder name is required',
  cardNumberRequired: 'Card number is required',
  cardExpirationRequired: 'Expiration date is required',
  cardCvcRequired: 'Cvc code is required',
  secretRequired: 'Secret is required',
  jwtRequired: 'JWT is required',
  emailRequired: 'Email is required',
  connectionRequired: 'UnivaPay not yet connected',
  cardNameMustBeValid: 'Cardholder must be between 2 to 30 digits',
  emailInvalid: 'Email looks invalid',
  ...textError,
});

export const amountFormatter = (
  value: string | number,
  typeCurrency: 'usd' | 'jpy'
): number => {
  if (!value) return 0;
  if (typeCurrency === 'jpy') {
    return Math.round(Number(value)) || 0;
  } else {
    return Number(value) || 0;
  }
};

export const cardNumberFormatter = (
  oldValue: string,
  newValue: string
): string => {
  if (oldValue.length > newValue.length) {
    return newValue;
  }

  return newValue
    .replace(/\W/gi, '')
    .replace(/(.{4})/g, '$1 ')
    .trim();
};

export const cardExpirationDateFormatter = (
  oldValue: string,
  newValue: string
): string => {
  if (oldValue.length > newValue.length) {
    return newValue;
  }

  return newValue
    .replace(/\W/gi, '')
    .replace(/(.{2})/g, '$1/')
    .substring(0, 5);
};

export const toNonAccentVietnamese = (str: string) => {
  str = str.replace(/A|Á|À|Ã|Ạ|Â|Ấ|Ầ|Ẫ|Ậ|Ă|Ắ|Ằ|Ẵ|Ặ/g, 'A');
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
  str = str.replace(/E|É|È|Ẽ|Ẹ|Ê|Ế|Ề|Ễ|Ệ/, 'E');
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
  str = str.replace(/I|Í|Ì|Ĩ|Ị/g, 'I');
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
  str = str.replace(/O|Ó|Ò|Õ|Ọ|Ô|Ố|Ồ|Ỗ|Ộ|Ơ|Ớ|Ờ|Ỡ|Ợ/g, 'O');
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
  str = str.replace(/U|Ú|Ù|Ũ|Ụ|Ư|Ứ|Ừ|Ữ|Ự/g, 'U');
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
  str = str.replace(/Y|Ý|Ỳ|Ỹ|Ỵ/g, 'Y');
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
  str = str.replace(/Đ/g, 'D');
  str = str.replace(/đ/g, 'd');
  // Some system encode vietnamese combining accent as individual utf-8 characters
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ''); // Huyền sắc hỏi ngã nặng
  str = str.replace(/\u02C6|\u0306|\u031B/g, ''); // Â, Ê, Ă, Ơ, Ư
  return str;
};

export const labelStyleUnivaPay = (
  attributes: Record<string, any>,
  disabled: boolean,
  target: string | string[] | null
) => {
  const { submitButton } = attributes || {};
  return {
    opacity: disabled ? 0.6 : 1,
    fontFamily: checkFont(submitButton.fontFamily, undefined, target),
    fontSize: submitButton.fontSize,
    ...(submitButton.fontSize && {
      lineHeight: submitButton.fontSize * 1.15,
      paddingVertical: 1.5,
    }),
    color: submitButton.color,
  };
};

export const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const checkDisabledButton = ({
  onlySaveCard,
  recurring,
  loading,
  isPassCard,
  isPassEmail,
  textError,
}: {
  onlySaveCard: boolean;
  recurring: Record<string, any> | null;
  loading: boolean;
  isPassCard: boolean;
  isPassEmail: boolean;
  textError: boolean;
}) => {
  if (onlySaveCard) {
    return loading || !isPassCard || !isPassEmail || textError;
  }

  if (recurring) {
    return loading || textError;
  }

  return loading || !isPassCard || !isPassEmail || textError;
};

export const checkStatusCard = ({
  onlySaveCard,
  recurring,
  isConnected,
  isPassCardName,
  isPassCardNumber,
  isPassCardExpiration,
  isPassCardCvc,
  isPassEmail,
  cardName,
  cardNumber,
  cardExpiration,
  cardCvc,
  amount,
  locale,
}: {
  onlySaveCard: boolean;
  recurring: Record<string, any> | null;
  isConnected: boolean;
  isPassCardName: boolean;
  isPassCardNumber: boolean;
  isPassCardExpiration: boolean;
  isPassCardCvc: boolean;
  isPassEmail: boolean;
  cardName: string;
  cardNumber: string;
  cardExpiration: string;
  cardCvc: string;
  amount: number;
  locale: string;
}): { status: 'notValidOrRequired' | null; message: string | null } => {
  const textErrors = getTextErrors();

  if (onlySaveCard) {
    const status =
      !isConnected ||
      !isPassEmail ||
      !isPassCardName ||
      !isPassCardNumber ||
      !isPassCardExpiration ||
      !isPassCardCvc
        ? 'notValidOrRequired'
        : null;

    const message = !isConnected
      ? textErrors.connectionRequired
      : !isPassEmail
      ? textErrors.emailInvalid
      : cardName && !isPassCardName
      ? textErrors.cardNameInvalid
      : cardNumber && !isPassCardNumber
      ? textErrors.cardNumberInvalid
      : cardExpiration && !isPassCardExpiration
      ? textErrors.cardExpirationInvalid
      : cardCvc && !isPassCardCvc
      ? textErrors.cardCvcInvalid
      : null;

    return { status, message };
  }

  if (recurring) {
    const status = amount === 0 ? 'notValidOrRequired' : null;

    const message =
      amount === 0 ? getMessage({ id: 'INVALID_AMOUNT', locale }) : null;

    return { status, message };
  }

  const status =
    !isConnected ||
    !isPassEmail ||
    amount === 0 ||
    !isPassCardName ||
    !isPassCardNumber ||
    !isPassCardExpiration ||
    !isPassCardCvc
      ? 'notValidOrRequired'
      : null;

  const message = !isConnected
    ? textErrors.connectionRequired
    : !isPassEmail
    ? textErrors.emailInvalid
    : amount === 0
    ? getMessage({ id: 'INVALID_AMOUNT', locale })
    : cardName && !isPassCardName
    ? textErrors.cardNameInvalid
    : cardNumber && !isPassCardNumber
    ? textErrors.cardNumberInvalid
    : cardExpiration && !isPassCardExpiration
    ? textErrors.cardExpirationInvalid
    : cardCvc && !isPassCardCvc
    ? textErrors.cardCvcInvalid
    : null;

  return { status, message };
};
