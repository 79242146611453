import { IDatePicker } from '@common/types/element';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { TouchableOpacity, Text, Platform } from 'react-native';
import createStyles from './style';
import { dateConfig, dateTimeConfig } from './helps/config';

import DateTimePickerApp from 'react-native-modal-datetime-picker';
import useDatePicker from './hook';
import { isEmpty, isNil } from 'lodash';
const DatePickerWeb = require('react-mobile-datepicker');

const DatePicker: FC<IDatePicker> = (props) => {
  const {
    attributes,
    showDatePicker,
    value,
    PlatformWeb,
    isDatePickerVisible,
    settings,
    handleConfirm,
    hideDatePicker,
    defaultValue,
  } = useDatePicker(props);

  const DatePickerCustom: any = DateTimePickerApp;

  const styles = createStyles(attributes);

  const [displayValue, setDisplayValue] = useState('');

  useEffect(() => {
    if (!isNil(value)) {
      setDisplayValue(value);
    }

    return () => setDisplayValue('');
  }, [value]);

  const renderLabel = useCallback(() => {
    if (!isEmpty(defaultValue) && isEmpty(displayValue)) {
      return defaultValue;
    } else {
      return displayValue;
    }
  }, [displayValue, defaultValue]);

  function lineHeight(fontSize: any) {
    const multiplier = fontSize > 20 ? 1.5 : 1;
    return parseInt(fontSize + fontSize * multiplier, 10);
  }

  return (
    <React.Fragment>
      <TouchableOpacity
        activeOpacity={1}
        style={[styles.container]}
        onPress={showDatePicker}
      >
        <Text
          style={[
            styles.text,
            {
              // display: 'flex',
              // alignItems: 'center',
              // textAlignVertical: 'center',
              // ...Platform.select({
              //   ios: {
              //     lineHeight: lineHeight(attributes.fontSize),
              //   },
              //   android: {},
              // }),
            },
          ]}
          numberOfLines={1}
          // adjustsFontSizeToFit
        >
          {renderLabel()}
        </Text>

        {PlatformWeb ? (
          <DatePickerWeb
            value={value ? new Date(value) : new Date()}
            min={new Date(1900, 0, 0)}
            max={new Date(2200, 0, 0)}
            isOpen={isDatePickerVisible}
            onSelect={handleConfirm}
            onCancel={hideDatePicker}
            confirmText={settings.confirmText}
            cancelText={settings.cancelText}
            theme={settings.theme}
            style={{ color: attributes.color }}
            dateConfig={
              attributes.defaultDateFormat === 'date'
                ? dateTimeConfig
                : dateConfig
            }
          />
        ) : (
          <DatePickerCustom
            isVisible={isDatePickerVisible}
            mode={attributes.defaultDateFormat === 'date' ? 'datetime' : 'date'}
            minimumDate={new Date(1900, 0, 0)}
            maximumDate={new Date(2200, 0, 0)}
            onConfirm={handleConfirm}
            onCancel={hideDatePicker}
            display={Platform.OS === 'ios' ? 'inline' : 'default'}
            cancelTextIOS="キャンセル"
            confirmTextIOS="OK"
          />
        )}
      </TouchableOpacity>
    </React.Fragment>
  );
};

export default DatePicker;
